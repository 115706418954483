.scroll-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.scroll-content {
    display: flex;
    animation: scroll 25s linear infinite;
}

.logo-container {
    flex-shrink: 0;
    padding: 0 2rem;
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}